.education {
    padding: 100px 0px;
    margin: 50px 0;
    margin-left: 120px;
    background-color: #f5f5f5;
}

.education hr {
    color: black;
    width: 400px;
    margin: 10px auto;
}

.education h2 {
    font-weight: bold;
    color: black;
}

.education .vertical-timeline-element-title {
    color: black;
}

.education .vertical-timeline-element-subtitle {
    color: #333333;
}

/* ========= DARK MODE ============ */

#dark .education {
    background-color: #121212 ;
}

#dark .education hr {
    color: #BB86FC ;
}

#dark .education h2 {
    color: #BB86FC ;
}

#dark .education .vertical-timeline-element-title {
    color: #BB86FC ;
}

#dark .education .vertical-timeline-element-subtitle {
    color: #BB86FC ;
}


#dark .vertical-timeline-element-date {
    color: #BB86FC ;
}

/* ========================== */
@media (max-width: 600px) {
    .education {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }
    .education h2 {
        padding-top: 20px;
    }
    .education hr {
        width: 280px;
        margin-bottom: 10px;
    }
}
