.sidebar-section{
    display: flex;
    background-color: black;
    background-color: white; /* minimalist*/
    color: #333333 !important; /* minimalist*/
}

.sidebar{
    height: 100vh;
    background-color: #1e1e2c;
    background-color: #f5f5f5; /* minimalist*/
    color: #333333 !important; /* minimalist*/
    width: 90px;
    position: fixed;
}

.sidebar-toggle{
    width: 200px;
}

.sidebar-toggle-icons{
    display: flex;
    align-items: right;
    justify-content: right;
    cursor: pointer;
}

.sidebar-toggle-icons p svg{
    background-color: #f29f67;
    background-color: white; /* minimalist */
    color: black;
    margin-top: 10px;
}


/* ==== MOBILE VIEW ==== */
@media (max-width:600px){
    .sidebar{
        display: none;
    }
}