.home-container{
    height: 300px;
    color: #333333;
    background-color: #f5f5f5;
    padding-left: 150px;
}

.home-content h2{
    padding: 5px;
    padding-top: 70px;
}

.home-content h1{
    color: black;
    padding: 5px;
}

.home-buttons{
    margin-top: 20px;
}

.home-buttons .btn-hire{
    background-color: white;
    color: #333333;
    width: 160px;
    border: 1px solid black;
}

.home-buttons .btn-hire:hover{
    background-color: black;
    color: white;
    width: 170px;
    transition: .7s;
}

.home-buttons .btn-cv{
    background-color: white;
    color: #333333;
    margin-left: 15px;
    border: 1px solid black;
}

.home-buttons .btn-cv:hover{
    opacity: 80%;
    transition: .7s;
    background-color: black;
}

.theme-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border: 2px solid black;
    margin-top: 20px;
    text-align: center;
    position: fixed;
    right: 0;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }

  .dark-bg-icon{
    background-color: black;
    color: white;
    height: 50px;
    width: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  /* =============== */
@media (max-width: 600px) {
    .home-container {
      padding: 0;
      margin: 0;
      margin-top: 50px;
    }
    .home-container h2 {
      font-size: 24px;
    }
    .home-container h1 {
      font-size: 24px;
    }
  }