

.navbar-profile-pic img{
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: contain;
    transform: scale(1.1);
    background-color: white;
    display: flex;
    vertical-align: middle;
    margin: 0 auto;
    margin-top:20px;
}

.nav-item{
    color: #333333;
    margin-top: 30px;
    margin-left: 25px;
}

.nav-link{
    margin-top: 20px;
    cursor: pointer;
}

.nav-link:hover{
    color: black;
    font-weight: 500;
}

.nav-link svg{
    margin-right: 10px;
}

.nav-link .active {
    color: black;
    font-weight: 500;
    border-left: 5px solid black;
    padding-left: 10px;
    text-decoration: none;
  }

  #dark.nav-items {
    background-color: red;
  }

  /* Minimalist design  */
