.contact{
    margin-left: 130px;
    margin-bottom: 60px;
    padding-top: 50px;
}

.card0{
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.459);
    border-radius: 0px;
}

.card1{
    height: 100%;
}

.card1 .row{
    height: 100%;
}

.card2{
    margin: 0px 40px;
}

.card1 .image{
    height: 100%;
    width: 100%;
}

.border-line{
    border-right: 1px solid black;
}

.line{
    height: 1px;
    width: 45%;
    background-color: black;
    margin-top: 10px;
}

.or{
    display: flex;
    width: 10%;
    font-weight: bold;
}

::placeholder{
    color: #333333;
    opacity: 1;
    font-weight: 300;
}

input,
textarea{
    padding: 10px 12px;
    border: 1px solid #f5f5f5;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: 2px;
    width: 100%;
    color: #333333;
    font-size: 1rem;
    letter-spacing: 1px;
}

input:focus,
textarea:focus{
    box-shadow: none !important;
    outline: 1px solid #333333 !important;
    outline-width: 0px;
}

button:focus{
    box-shadow: none;
    outline-width: 0px;
}

.btn{
    background-color: #333333;
    color: white;
    border-radius: 2px;
    margin-top: 15px;
    padding: 10px;
}

.btn:hover{
    background-color: black;
    color: white;
    border: 1px solid black;
}


/* ======================== */
@media (max-width: 600px) {
    .contact {
      margin: 0;
      padding: 0;
      margin-top: 50px;
    }
    .contact .card2 {
      margin: 0;
    }
  
    .contact .card2 h6 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }