.work {
    background-color: white;
    margin-left: 120px;
}

.work-exp {
    padding: 60px 0px;
}

.work-exp h2 {
    font-weight: bold;
    color: black;
}

.work-exp hr {
    color: black;
    width: 400px;
    margin: 10px auto;
}

.work-exp .vertical-timeline-element-title {
    color: black;
}

.work-exp .vertical-timeline-element-subtitle {
    color: #333333;
}

/* ===== DARK MODE ========== */
#dark .work {
    background-color: #121212;
}

#dark .work-exp h2 {
    color: white;
}

/* =================== */

@media (max-width: 600px) {
    .work {
        margin: 0;
        padding: 0;
        margin-top: 50px;
    }
    .work hr {
        width: 280px;
    }
}
