.about{
    margin: 30px 0;
    padding: 20px;
    margin-left: 200px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}



.about-img img{
    border-radius: 10px;
    width: 100%;
    /* height: 400px; */
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-content h1{
    color: black;
    font-family: cursive;
    border-bottom: 2px solid black;
    text-align: center;
}

.about-content p{
    text-align: justify;
    padding: 10px;
    color: #333333;
}

/* DARK MODE  */

#dark .about{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.15);
    color: #BB86FC ;
}

#dark .about-content h1 {
    color: #BB86FC ;
    border-bottom: 2px solid #BB86FC ;
  }
  
#dark .about-content p{
  color: #BB86FC ;
}
  
/* ================================ */
@media (max-width: 600px) {
    .about {
      margin: 0;
      padding: 0;
      margin-top: 20px;
      width: 100%;
    }
    .about-content h1 {
      margin-top: 10px;
    }
  }