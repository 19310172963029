.project{
    margin: 50px 0;
    margin-left: 140px;
}

.project hr{
    color: black;
    width: 400px;
    margin: 10px auto;
}

.project h2{
    font-weight: bold;
    color: black;
}

#ads{
    margin: 30px 0;
}

#ads .card-notify-badge{
    position: absolute;
    left: -10px;
    top: -20px;
    background: black;
    text-align: center;
    border-radius: 30px;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
}

.project .card img{
    height: 150px;
    width: 100%;
}

.card-detail-badge{
    background-color: #f5f5f5;
    border: 1px solid #333333;
    border-radius: 30px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
}

.card-image-overlay{
    font-size: 20px;
}

.card-image-overlay span{
    display: inline-block;
    margin-left: 5px;
}

.project .card:hover{
    background-color: white;
    border-radius: 4px;
    box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
    transition: all 1s ease;
}

.ad-btn{
    text-transform: uppercase;
    width: 150px;
    height: 40px;
    display: block;
    text-align: center;
    background-color: black;
    color: white;
    border-radius: 80px;
    font-size: 16px;
    line-height: 35px;
    text-decoration: none;
    margin: 20px auto;
    overflow: hidden;
    position: relative;
}

.ad-btn:hover{
    background-color: white;
    color: black;
    background: transparent;
    border: 2px solid black;
    transition: all 0.5s ease;
    box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
}

/* =============================== */
@media (max-width: 600px) {
    .project {
      margin: 0;
      padding: 0;
      margin-top: 50px;
    }
    .project hr {
      width: 280px;
    }
    .project .card {
      margin-top: 30px;
    }
  }